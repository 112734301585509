<script lang="ts" setup></script>

<template>
  <div class="wrapper">
    <div class="no-select box-main">
      <div class="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
