import tmsIcon from '@/assets/decl_step_icon/tms_icon.svg'
import incomesIcon from '@/assets/decl_step_icon/incomes_icon.svg'
import refundsIcon from '@/assets/decl_step_icon/refund_icon.svg'
import personalIcon from '@/assets/decl_step_icon/personal_icon.svg'
import filesIcon from '@/assets/decl_step_icon/files_icon.svg'
import showIcon from '@/assets/decl_step_icon/res_icon.svg'
import goodsIcon from '@/assets/decl_step_icon/good_icon.svg'
import cfrIcon from '@/assets/decl_step_icon/cfr_icon.svg'
import { computed, ComputedRef } from 'vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import usePolitic from '@/use/usePolitic'
import { GoodsTypeName } from '@/entityes/invoice/invoice.type'
import { useStepper } from '@/entityes/invoice/useStepper'
import { DeclarationAction } from '@/entityes/invoice/declaration/declaration.type'

export interface IMenuItem {
  tag: string
  title: string
  icon: string | undefined
  good_id?: ComputedRef<number | undefined>
  sortIndex: number
  serverTag: string | string[] | undefined
}
export function useInvoiceMenu() {
  const invoiceStore = useInvoiceStore()
  const politics = usePolitic()
  const resolvedTms = ['Missing_resolved', 'Transfer_resolved', 'Short_resolved']

  const menuTitles: IMenuItem[] = [
    {
      tag: 'tms',
      title: 'Дефицит данных',
      icon: tmsIcon,
      good_id: computed(() => invoiceStore.invoice?.goods?.Declaration),
      sortIndex: 2,
      serverTag: ['Transfer', 'Missing', 'Short'],
    },
    {
      tag: 'add_incomes',
      title: 'Доп. доходы',
      icon: incomesIcon,
      good_id: computed(() => invoiceStore.invoice?.goods?.Declaration),
      sortIndex: 4,
      serverTag: 'Add_income',
    },
    {
      tag: 'deductions',
      title: 'Вычеты и убытки',
      icon: refundsIcon,
      good_id: computed(() => invoiceStore.invoice?.goods?.Declaration),
      sortIndex: 4,
      serverTag: 'Deduction',
    },
    {
      tag: 'personal_data',
      title: 'Персональные данные',
      icon: personalIcon,
      // good_id: computed(() => invoiceStore.invoice?.goods.Declaration),
      sortIndex: 3,
      serverTag: 'Personal_data',
    },
    {
      tag: 'cfr',
      title: 'ОДС',
      icon: cfrIcon,
      good_id: computed(() => invoiceStore.invoice?.goods?.Cfr),
      sortIndex: 6,
      serverTag: 'Add_account',
    },
    // {
    //   tag: 'taxplan',
    //   title: 'Налоговое планирование',
    //   icon: histogram,
    //   good_id: computed(() => invoiceStore.invoice?.goods.Declaration),
    //   sortIndex: 7,
    // },
    {
      tag: 'good_select',
      title: 'Товары и стоимость',
      icon: goodsIcon,
      sortIndex: 3,
      serverTag: undefined,
    },
    {
      tag: 'invoice show',
      title: 'Итоговые документы',
      icon: showIcon,
      sortIndex: 8,
      serverTag: 'invoice show',
    },
    {
      tag: 'file_in_invoice',
      title: 'Отчеты брокеров',
      icon: filesIcon,
      sortIndex: 1,
      serverTag: undefined,
    },
    {
      tag: 'pre_add_deductions',
      title: 'Доходы и вычеты',
      icon: incomesIcon,
      sortIndex: 2,
      serverTag: undefined,
    },
    // {
    //   tag: 'calculated_incomes',
    //   title: 'Торговые операции',
    //   icon: coins,
    //   sortIndex: 7,
    // },
  ]
  const { checkingForTmsFromInvoice, checkingForTms, currentStep } = useStepper()

  const getInvoiceRoutes = computed<IMenuItem[]>(() => {
    if (invoiceStore.invoice === null) return []
    const invoiceStatus = invoiceStore.invoice.status.invoice_status
    const declarationActions = invoiceStore.invoice.status.actions_for_goods?.Declaration
    const cfrActions = invoiceStore.invoice.status.actions_for_goods?.Cfr

    if (invoiceStatus === 'new')
      return menuTitles
        .filter((item) => ['file_in_invoice'].includes(item.tag))
        .sort((item, item2) => item.sortIndex - item2.sortIndex)
    if (invoiceStatus === 'file_uploaded')
      return menuTitles
        .filter((item) => ['file_in_invoice', 'file_uploaded', 'pre_add_deductions'].includes(item.tag))
        .sort((item, item2) => item.sortIndex - item2.sortIndex)
    if (invoiceStatus === 'pre_add_services_selected')
      return menuTitles
        .filter((item) => ['file_in_invoice', 'file_uploaded', 'pre_add_deductions', 'good_select'].includes(item.tag))

        .sort((item, item2) => item.sortIndex - item2.sortIndex)
    if (invoiceStatus === 'payed') {
      const steps: IMenuItem['tag'][] = ['file_in_invoice']
      const goods = Object.keys(invoiceStore.invoice.goods) as GoodsTypeName[]
      // if (goods.includes('TaxPlan'))
      if (goods.includes('Declaration')) {
        const hasTms = checkingForTmsFromInvoice(invoiceStore.invoice.tms)
        const hasResolvedTms = checkingForTms(declarationActions as DeclarationAction[])
        if (hasTms) steps.push('tms')
        if (hasResolvedTms) steps.push('tms')

        if (hasResolvedTms || !hasTms) {
          steps.push('personal_data')
          if (politics.isCustomer.value) {
            if (
              declarationActions.every((item) => resolvedTms.includes(item)) &&
              !cfrActions?.includes('Add_account')
            ) {
              steps.push('invoice show')
            }
            if (!declarationActions.includes('Personal_data')) {
              steps.push('add_incomes')
              if (!declarationActions.includes('Add_income')) {
                steps.push('deductions')
              }
              if (!declarationActions.includes('Deduction')) {
                if (goods.includes('Cfr')) {
                  steps.push('cfr')
                  if (cfrActions) {
                    if (cfrActions.length > 0) {
                      steps.push('cfr')
                    } else {
                      steps.push('invoice show')
                    }
                  }
                } else {
                  steps.push('invoice show')
                }
              }
            }
          }
        }
        if (politics.isAdmin.value) {
          steps.push(...['invoice show', 'personal_data'])
          if (goods.includes('Cfr')) {
            steps.push('cfr')
          }
          if (goods.includes('Declaration')) {
            steps.push(...['add_incomes', 'deductions'])
          }
        }
      }

      if (goods.includes('Cfr') && !goods.includes('Declaration')) {
        steps.push('personal_data')
        if (!declarationActions?.includes('Personal_data')) {
          if (cfrActions) {
            if (cfrActions.includes('Add_account')) {
              if (Object.keys(invoiceStore.invoice.personal_data as Record<string, any>).length !== 0) {
                steps.push('cfr')
              }
            } else {
              steps.push('cfr')
              steps.push('invoice show')
            }
          }
        }
      }

      return menuTitles
        .filter((item) => steps.includes(item.tag))
        .sort((item, item2) => item.sortIndex - item2.sortIndex)
    }
    return []
  })

  return {
    getInvoiceRoutes,
    menuTitles,
  }
}
