<script lang="ts" setup>
import { useCfrRepository } from '@/entityes/invoice/cfr/cfr.repository'
import { computed, ref } from 'vue'
import EditorBrokerAccount from '@/04-Widgets/CfrWidget/ui/EditorBrokerAccount.vue'
import { IBrokerAccount, IEditBrokerAccount } from '@/entityes/invoice/cfr/cfr.type'
import { useCfrStore } from '@/entityes/invoice/cfr/cfr.store'
import { ElNotification } from 'element-plus'
import { CirclePlusFilled, UploadFilled } from '@element-plus/icons-vue'
import useCountryDescriber from '@/describers/useCountryDescriber'
import { useStepper } from '@/entityes/invoice/useStepper'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { IInvoice } from '@/entityes/invoice/invoice.type'

interface IProps {
  invoiceId: string | number
  good_id: string | number
}
const props = defineProps<IProps>()
const { getBrokerAccounts, createBrokerAccount, destroyBrokerAccount, updateBrokerAccount, switchCfrStep } =
  useCfrRepository()
const cfrStore = useCfrStore()
const loading = ref(false)
const editor = ref<IEditBrokerAccount | null>(null)
const brokerNames = computed(() => {
  if (!!cfrStore.brokerAccounts) {
    return [...new Set(cfrStore.brokerAccounts.map((i) => i.broker_name))]
  }
})
const active = ref('')
const invoiceStore = useInvoiceStore()
const stepper = useStepper()
const { getInvoice } = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()

function openEditor(id?: string | number) {
  if (!!id) {
    editor.value?.open(props.good_id, id)
  } else {
    editor.value?.open()
  }
}

function saveBrokerAccount(val) {
  loading.value = true
  createBrokerAccount(props.good_id, val)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'создана запись о брокерском аккаунте',
        type: 'success',
      })
      getIndexBrokerAccounts()
      editor.value?.close()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `создания записи о брокерском аккаунте | ${err}`,
        type: 'error',
      })
    })
    .finally(() => (loading.value = false))
}

function deleteAccount(id: string | number) {
  loading.value = true
  destroyBrokerAccount(props.good_id, id)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'удалена запись о брокерском аккаунте',
        type: 'success',
      })
      getIndexBrokerAccounts()
      editor.value?.close()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `удаления записи о брокерском аккаунте | ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      loading.value = false
      editor.value?.close()
    })
}

function updateAccount(val: IBrokerAccount) {
  loading.value = true
  updateBrokerAccount(props.good_id, val as IBrokerAccount)
    .then(() => {
      ElNotification({
        title: 'Успешно',
        message: 'обновлена запись о брокерском аккаунте',
        type: 'success',
      })
      getIndexBrokerAccounts()
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `обновления записи о брокерском аккаунте | ${err}`,
        type: 'error',
      })
    })
    .finally(() => {
      loading.value = false
      editor.value?.close()
    })
}

function getIndexBrokerAccounts() {
  getBrokerAccounts(props.good_id).finally(() => (loading.value = false))
}
function accountsWithBrokerNames(name: string) {
  if (!!cfrStore.brokerAccounts) {
    return cfrStore.brokerAccounts.filter((i) => i.broker_name === name) as IBrokerAccount[]
  }
}
function getCountryName(oksmCode: string) {
  return useCountryDescriber().find((i) => Number(i.code) === Number(oksmCode))?.shortname
}
function spliceStep() {
  switchCfrStep(props.good_id, { step: ['Add_account'] })
    .then(() => {
      getInvoice(props.invoiceId).then((res: IInvoice) => {
        initAsync(res).then((res) => {
          stepper.selectStep(res)
        })
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса перехода на следующий шаг ${err}`,
      })
    })
    .finally(() => (loading.value = false))
}
getIndexBrokerAccounts()
</script>

<template>
  <div class="container-list">
    <div class="flex items-center justify-between mb-4">
      <h1 class="mb-4">Отчет о движении средств</h1>
      <el-button :icon="CirclePlusFilled" class="mb-4" type="primary" @click="openEditor()">Добавить счет</el-button>
    </div>
    <div>
      <p class="mb-2">Нажмите кнопку «Изменить» и проверьте правильность указанных данных.</p>
      <p class="mb-2">Нажмите кнопку «Добавить счет», чтобы добавить информацию по неуказанным счетам.</p>
      <p class="mb-2">Отчет о движении средств будет подготовлен в соответствии с данными, указанными на этом шаге.</p>
    </div>
    <div v-if="!!cfrStore.brokerAccounts" class="step-box">
      <div v-for="broker in brokerNames">
        <!--        <el-collapse v-model="active">-->
        <!--          <el-collapse-item>-->
        <!--            <template #title>-->
        <p class="font-weight--extra px-4 py-2">{{ broker }}</p>
        <!--            </template>-->
        <div class="grid grid-rows-* grid-cols-3 gap-4 px-4">
          <div
            v-for="account in accountsWithBrokerNames(broker)"
            :key="account.id"
            class="bg-gray p-4 border-2 dark:border-dark-gray border-light-white bg-light-gray dark:bg-dark-dark_gray rounded-xl"
          >
            <div>
              <p class="mb-2">
                Номер счета: <span class="font-weight--extra"> {{ account.account_number }}</span>
              </p>
              <p class="mb-2">
                Общий счет: <span class="font-weight--extra">{{ account.common_account ? 'да' : 'нет' }}</span>
              </p>
              <p class="mb-2">
                Дата открытия:
                <span class="font-weight--extra">{{ account.opening_account_date?.split('T')[0] }}</span>
              </p>
              <p class="mb-2">
                Дата закрытия:
                <span class="font-weight--extra">{{
                  account.closed_account_date ? account.closed_account_date?.split('T')[0] : 'счет открыт'
                }}</span>
              </p>
              <p class="mb-4">
                Страна:
                <span class="font-weight--extra">{{ getCountryName(account.broker_oksm_code) }}</span>
              </p>
              <el-button-group size="small">
                <el-button type="primary" @click="openEditor(account.id)">Изменить</el-button>
                <el-popconfirm
                  title="Вы хотите удалить этот счет?"
                  confirm-button-text="удалить"
                  cancel-button-text="отменить"
                  @confirm="deleteAccount(account.id)"
                  width="300px"
                >
                  <template #reference>
                    <el-button type="danger">Удалить</el-button>
                  </template>
                </el-popconfirm>
              </el-button-group>
            </div>
          </div>
        </div>
        <!--          </el-collapse-item>-->
        <!--        </el-collapse>-->
      </div>
      <el-empty v-if="cfrStore.brokerAccounts.length === 0" description="У вас нет ни одного аккаунта" />
      <div class="bg-gray p-4">
        <el-button
          :disabled="cfrStore.brokerAccounts.length === 0"
          :loading="loading"
          @click="spliceStep"
          :icon="UploadFilled"
          class="button-main"
          type="success"
        >
          Отправить данные
        </el-button>
      </div>
    </div>
    <editor-broker-account ref="editor" @save="saveBrokerAccount" @update="updateAccount" />
  </div>
</template>

<style scoped lang="sass"></style>
