import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "px-2 py-1 bg-green mb-4 border" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "text-center font-weight--extra" }
const _hoisted_4 = ["src"]

import { IMenuItem, useInvoiceMenu } from '@/07-Shared/model/useInvoiceMenu'
import usePolitic from '@/use/usePolitic'
import { useRoute } from 'vue-router'

interface Props {
  invoiceId: string | number
  showLoader: boolean
  invoicePayed: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceSidebarMenu',
  props: {
    invoiceId: {},
    showLoader: { type: Boolean },
    invoicePayed: { type: Boolean }
  },
  setup(__props: any) {

const props = __props
const route = useRoute()
const { isAdmin, isPartner } = usePolitic()
const { getInvoiceRoutes } = useInvoiceMenu()

return (_ctx: any,_cache: any) => {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_unref(isAdmin) || _unref(isPartner) ? 'left-menu-admin' : 'left-menu-slot')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (props.showLoader)
          ? (_openBlock(), _createBlock(_component_el_icon, {
              key: 0,
              size: 18,
              class: "is-loading mr-2",
              color: "#FF9A9D"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Loading)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["circle mr-2", props.invoicePayed ? 'circle-success' : 'circle-fail'])
            }, null, 2)),
        _createElementVNode("p", _hoisted_3, "Заказ #" + _toDisplayString(props.invoiceId), 1)
      ])
    ]),
    _createVNode(_component_el_menu, {
      style: {"border":"none"},
      class: "bg-gray"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getInvoiceRoutes), (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            class: _normalizeClass(["flex items-center clickable mb-4 cursor-point", _unref(route).name === item.tag ? 'bg-purple font-color--white font-weight--semi' : '']),
            key: item.tag,
            to: {
          name: item.tag,
          params: {
            id: _ctx.invoiceId,
            good_id: item?.good_id?.value || '',
          },
        }
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: item?.icon,
                alt: "icon",
                class: _normalizeClass(["mr-2", _unref(route).name === item.tag ? 'img-white' : 'img']),
                width: "18"
              }, null, 10, _hoisted_4),
              _createTextVNode(" " + _toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1032, ["class", "to"]))
        }), 128))
      ]),
      _: 1
    })
  ], 2))
}
}

})