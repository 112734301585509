import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-list" }

import { computed, watch } from 'vue'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { IMenuItem, useInvoiceMenu } from '@/07-Shared/model/useInvoiceMenu'
import { isArray, isString } from 'lodash'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'

interface IProps {
  invoice: IInvoice | undefined
  currentStep: string | undefined
  hasTms: boolean
  hasResolvedTms: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'UserSteps',
  props: {
    invoice: {},
    currentStep: {},
    hasTms: { type: Boolean },
    hasResolvedTms: { type: Boolean }
  },
  setup(__props: any) {

const props = __props
const { menuTitles } = useInvoiceMenu()
const allSteps = computed(() =>
  menuTitles.filter((i) => i.serverTag).sort((item, item2) => item.sortIndex - item2.sortIndex)
)

const filteredSteps = computed(() => {
  return allSteps.value.filter((i: IMenuItem) => {
    const declarationSteps = props.invoice?.status.actions_for_goods?.Declaration
    const cfrSteps = props.invoice?.status.actions_for_goods?.Cfr

    if (isArray(i.serverTag)) {
      if (props.hasTms || props.hasResolvedTms) {
        return i
      }
    } else if (isString(i.serverTag)) {
      if (cfrSteps) {
        if (['Personal_data', 'Add_account'].includes(i.serverTag)) {
          return i
        }
      }
      if (declarationSteps) {
        if (i.serverTag !== 'Add_account') {
          return i
        }
      }
    }
    if (i.serverTag === 'invoice show') {
      return i
    }
  })
})

const stepIndexMap = computed(() =>
  filteredSteps.value.reduce<Record<string, number>>((acc, step, index) => {
    acc[step.tag] = index
    return acc
  }, {})
)

const active = computed(() => {
  if (!props.currentStep) return 0
  if (props.currentStep === 'invoice show') {
    return 6
  } else {
    return stepIndexMap.value[props.currentStep] ?? 0
  }
})

return (_ctx: any,_cache: any) => {
  const _component_el_step = _resolveComponent("el-step")!
  const _component_el_steps = _resolveComponent("el-steps")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_steps, {
      active: active.value,
      "finish-status": "success"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredSteps.value, (item) => {
          return (_openBlock(), _createBlock(_component_el_step, {
            title: item.title,
            key: item.tag
          }, null, 8, ["title"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}
}

})