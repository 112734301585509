<script setup lang="ts">
import { IMenuItem, useInvoiceMenu } from '@/07-Shared/model/useInvoiceMenu'
import usePolitic from '@/use/usePolitic'
import { useRoute } from 'vue-router'

interface Props {
  invoiceId: string | number
  showLoader: boolean
  invoicePayed: boolean
}
const props = defineProps<Props>()
const route = useRoute()
const { isAdmin, isPartner } = usePolitic()
const { getInvoiceRoutes } = useInvoiceMenu()
</script>

<template>
  <div :class="isAdmin || isPartner ? 'left-menu-admin' : 'left-menu-slot'">
    <div class="px-2 py-1 bg-green mb-4 border">
      <div class="flex items-center">
        <el-icon :size="18" v-if="props.showLoader" class="is-loading mr-2" color="#FF9A9D">
          <Loading />
        </el-icon>
        <div v-else class="circle mr-2" :class="props.invoicePayed ? 'circle-success' : 'circle-fail'" />
        <p class="text-center font-weight--extra">Заказ #{{ props.invoiceId }}</p>
      </div>
    </div>
    <el-menu style="border: none" class="bg-gray">
      <router-link
        class="flex items-center clickable mb-4 cursor-point"
        v-for="item in getInvoiceRoutes"
        :class="route.name === item.tag ? 'bg-purple font-color--white font-weight--semi' : ''"
        :key="item.tag"
        :to="{
          name: item.tag,
          params: {
            id: invoiceId,
            good_id: item?.good_id?.value || '',
          },
        }"
      >
        <img
          :src="item?.icon"
          alt="icon"
          class="mr-2"
          width="18"
          :class="route.name === item.tag ? 'img-white' : 'img'"
        />
        {{ item.title }}
      </router-link>
    </el-menu>
  </div>
</template>

<style lang="sass" scoped></style>
