<script lang="ts" setup>
import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import { ElNotification } from 'element-plus'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
import CurrencyDescriber from '@/describers/CurrencyDescriber'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
const Transfers = defineAsyncComponent(() => import('@/05-Features/Transfers/ui/Transfers.vue'))
// const UnclosedShorts = defineAsyncComponent(() => import('@/05-Features/UnclosedShorts/ui/UnclosedShorts.vue'))
const Missings = defineAsyncComponent(() => import('../../05-Features/Missings/ui/Missings.vue'))

interface IProps {
  id: string | number
  good_id: string | number
}
const props = defineProps<IProps>()
const invoiceRepository = useInvoiceRepository()
const declarationStore = useDeclarationStore()
const { getDeclaration, getTransfers, getMissings } = useDeclarationRepository()
const declarationActions = computed(() => declarationStore.declaration && declarationStore.declaration.actions)
const cPage = ref(1)
const tPages = ref(1)
const pageToChange = ref(0)
const { initAsync } = useAsyncInvoiceReload()

const changePage = (val: number) => {
  cPage.value = val
  init(cPage.value)
}
const totalLoading = {
  transferLoading: ref(false),
  missingLoading: ref(false),
  // shortLoading: ref(false),
}
const TmsDescriber = [
  {
    name: 'Transfer',
    getMethod: (page: number) => {
      totalLoading.transferLoading.value = true
      getTransfers(props.good_id, page)
        .then((res) => {
          cPage.value = res.current_page
          tPages.value = res.total_pages
        })
        .finally(() => {
          totalLoading.transferLoading.value = false
        })
    },
    resolveName: 'Transfer_resolved',
    component: Transfers,
    show: ref(false),
  },
  {
    name: 'Missing',
    getMethod: (page: number) => {
      totalLoading.missingLoading.value = true
      getMissings(props.good_id, page)
        .then((res) => {
          cPage.value = res.current_page
          tPages.value = res.total_pages
        })
        .finally(() => {
          totalLoading.missingLoading.value = false
        })
    },
    resolveName: 'Missing_resolved',
    component: Missings,
    show: ref(false),
  },
  // {
  //   name: 'Short',
  //   getMethod: (page) => {
  //     totalLoading.shortLoading.value = true
  //     getUnclosedShorts(props.good_id, page)
  //       .then((res) => {
  //         cPage.value = res.current_page
  //         tPages.value = res.total_pages
  //       })
  //       .finally(() => {
  //         totalLoading.shortLoading.value = false
  //       })
  //   },
  //   resolveName: 'Short_resolved',
  //   component: UnclosedShorts,
  //   show: ref(false),
  // },
]
const haveBaseRequest = ref(false)
const isLoading = computed(
  () => {
    return Object.values(totalLoading).some((loading) => loading.value)
  }
  // Object.keys(totalLoading).reduce((acc, key) => {
  //   return acc && totalLoading[key].value
  // }, false)
)
const getAllTMS = (val: string[] | null, page: number) => {
  haveBaseRequest.value = false
  TmsDescriber.forEach((item) => {
    if (val?.includes(item.name) && !haveBaseRequest.value) {
      haveBaseRequest.value = true
      item.getMethod(page)
      item.show.value = true
    }
    if (val?.includes(item.resolveName)) {
      item.getMethod(page)
      item.show.value = true
    }
  })
}
const showComponent = computed(() => TmsDescriber.filter((item) => item.show.value))
const renderComponent = computed(() => {
  return (
    declarationStore.tms?.transfers?.length > 0 || declarationStore.tms?.missings?.length > 0
    // declarationStore.tms?.unclosed_shorts?.length > 0
  )
})
function init(page: number) {
  invoiceRepository.getInvoice(props.id).then((res) => {
    initAsync(res).then(() => {
      getDeclaration(props.good_id)
        .then(() => {
          getAllTMS(declarationActions.value, page)
        })
        .catch((err) => {
          ElNotification({
            title: 'Ошибка',
            message: `Получения декларации ${err}`,
          })
        })
    })
  })
}
watch(pageToChange, (val) => {
  init(val)
})
init(cPage.value)
</script>

<template>
  <div class="container-list">
    <h1 class="mb-4">Дефицит данных</h1>
    <p class="mb-8">
      В вашем отчете отсутствуют данные по некоторым позициям. Загрузите отчеты брокера с этой информацией.
    </p>
    <el-pagination
      class="mb-4"
      :hide-on-single-page="tPages === 1"
      :current-page="cPage"
      :total="tPages"
      layout="prev, pager, next"
      :page-size="1"
      @current-change="changePage"
      size="small"
      background
    />
    <clear-loader v-if="isLoading" />
    <div v-else>
      <div v-for="item in showComponent" :key="item.name">
        <component
          @save="init(cPage)"
          :invoice-id="props.id"
          :is="item.component"
          v-if="renderComponent"
          :currency="CurrencyDescriber"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass"></style>
