<script setup lang="ts">
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import AddCfrAccount from '@/04-Widgets/CfrWidget/ui/AddCfrAccount.vue'

const invoiceStore = useInvoiceStore()
</script>

<template>
  <div v-if="!!invoiceStore.invoice">
    <AddCfrAccount :invoice-id="invoiceStore.invoice.id" :good_id="invoiceStore.invoice?.goods?.Cfr" />
  </div>
</template>

<style scoped lang="sass"></style>
