<script lang="ts" setup>
import useSummaryNew from '@/05-Features/DeclarationSummary/model/useSummaryNew'
import { computed, UnwrapRef } from 'vue'
import { GoodsTypeName, IInvoice } from '@/entityes/invoice/invoice.type'
import { useDialog } from 'element-plus'
import { usePDRepository } from '@/entityes/invoice/personal_data/pd.repository'

interface IProps {
  actions: Record<GoodsTypeName, string[]>
  isAdmin: boolean
  tms: IInvoice['tms']
}
const props = defineProps<IProps>()
const { getCurrentPersonalDataCard } = usePDRepository()

const stepStatus = computed(() => {
  if (Object.values(props.tms).some((item) => item)) return 'не готовы (дефицит данных)'
  return 'Документы сформированы'
})
const titleList = {
  additional_incomes: 'Дополнительные доходы',
  deductions: 'Вычеты',
  personal_data: 'Персональные данные',
}

const { summary, taxes15, taxes13 } = useSummaryNew()
</script>

<template>
  <div class="flex items-center mb-2" v-if="props.isAdmin">
    <div class="font-weight--extra font-color--deep-gray mr-2">Статус документов:</div>
    <span :class="!stepStatus.includes('не') ? 'font-color--green' : 'font-color--red'" class="font-weight--extra">
      {{ stepStatus }}
    </span>
  </div>
  <div class="flex items-center font-color--deep-gray mb-2">
    <div class="mr-2 font-weight--extra">Налог 13%:</div>
    <div>{{ taxes13 }} ₽</div>
  </div>
  <div class="flex items-center font-color--deep-gray mb-2">
    <div class="mr-2 font-weight--extra">Налог 15%:</div>
    <div>{{ taxes15 }} ₽</div>
  </div>
  <div v-if="Object.keys(summary).length > 0">
    <div v-for="(item, index) in summary" :key="index + Date.now()" class="mb-2">
      <div v-for="(value, key) in item" :key="key">
        <div class="flex items-center font-color--deep-gray">
          <div class="mr-2 font-weight--extra">{{ titleList[key] }}:</div>
          <div>{{ key === 'personal_data' ? value : value.length }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
