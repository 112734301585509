<script lang="ts" setup>
import useColors from '@/07-Shared/model/useColors'

const { colors, isDark } = useColors()
</script>

<template>
  <h1 class="mb-4">Налоговые вычеты</h1>
  <p class="mb-4">Загрузите все необходимые документы по добавленным в заказ вычетам.</p>
</template>

<style lang="sass" scoped></style>
