import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "step-box mt-8" }
const _hoisted_2 = { class: "p-4 mb-8" }
const _hoisted_3 = {
  key: 0,
  class: "grid grid-rows-* grid-cols-3 gap-4"
}
const _hoisted_4 = { class: "border border-2 dark:bg-dark-dark_gray bg-gray p-4" }
const _hoisted_5 = { class: "flex items-center mb-8" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = {
  key: 1,
  class: "flex justify-between items-center"
}
const _hoisted_9 = { class: "flex items-center cursor-pointer hover:opacity-50" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { class: "bg-gray p-4" }

import { SidebarLoader } from '@/05-Features/SidebarLoader'
import fileLoader, { FileLoaderType } from '@/06-Entities/File/model/FileLoader'
import { ILoaderDescriber } from '@/entityes/invoice/file/loader/loader.type'
import { computed, Ref, ref, watch } from 'vue'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { IAddService, IAddServicesList, IInvoice } from '@/entityes/invoice/invoice.type'
import AddIncomesRefundsDescriber from '@/describers/AddIncomesRefundDescriber'
import img from '@/07-Shared/ui/ImgDescriber'
import GuruLogo from '@/assets/guru_logo.svg'
import { FileFromServer } from '@/entityes/invoice/file/file.type'
import { SetUp, SuccessFilled, UploadFilled } from '@element-plus/icons-vue'
import useColors from '@/07-Shared/model/useColors'
import { FileFromServerItem, transformFileFromServer } from '@/06-Entities/File'
import { ElNotification, ElMessageBox } from 'element-plus'
import { useStepper } from '@/entityes/invoice/useStepper'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'

interface IProps {
  invoiceId: string | number
  goodId: string | number
  hasAdds: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'AddIncomeCustomerUI',
  props: {
    invoiceId: {},
    goodId: {},
    hasAdds: { type: Boolean }
  },
  setup(__props: any) {

const props = __props
const { getAdditionalServices, updateAdditionalService } = useInvoiceRepository()
const { colors, isDark } = useColors()
const showLoaderSideBar = ref(false)
const addServices: Ref<IAddService[] | undefined> = ref(undefined)
const { incomesDeductionsDescribers: describer, incomes } = AddIncomesRefundsDescriber()
const approvedAddIncomesTags: string[] = incomes.items.reduce((acc: string[], item) => {
  acc.push(item.tag)
  return acc
}, [])
const renderAddServices = computed(() => {
  return addServices.value?.filter((service) => approvedAddIncomesTags.includes(service.service_type))
})
const loading = ref(false)
const loaderConfig = ref<ILoaderDescriber | null>(null)
const loader = ref<FileLoaderType | undefined>(undefined)
const service = ref<IAddService | undefined>(undefined)
const stepper = useStepper()
const { getInvoice } = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()
const hasFiles = computed(() => {
  if (!props.hasAdds) {
    return true
  } else {
    return renderAddServices.value?.some((item) => item.files.length > 0)
  }
})
const { switchDeclarationStep } = useDeclarationRepository()

function initLoader(type: string, open: boolean) {
  const getName = describer.find((i) => i.tag === type)?.name
  loaderConfig.value = {
    title: `${getName}`,
    text: `Нажмите или перетащите файлы ${getName}`,
    serverTag: 'service_files',
    name: 'AdditionalServiceFiles',
    fileFormat: /.*\.(csv|xlsx|xls|pdf|xml|jpeg|jpg|png|json|docx|doc|XLSX|PDF|CSV|XML|JPEG|JPG|PNG)$/,
    img: img.csv,
    logo: GuruLogo,
  } as ILoaderDescriber
  loader.value = fileLoader(loaderConfig.value)
  service.value = addServices.value?.find((i) => i.service_type === type)
  showLoaderSideBar.value = open
}

function saveFiles() {
  loading.value = true
  if (!!loader.value) {
    const body = {
      id: service.value?.id,
      service_type: service.value?.service_type,
      incomes_group: 1,
      service_files: {
        additional_service_files: {
          files: [...loader.value?.localFiles.files],
          file_names: [...loader.value?.localFiles.file_names],
          delete_files: [...loader.value.deleteFiles],
        },
      },
    }
    updateAdditionalService(props.invoiceId, body)
      .then(() => {
        getServices()
        ElNotification({
          title: 'Успешно',
          message: `обновлены данные для ${describer.find((i) => i.tag === service.value?.service_type)?.name}`,
          type: 'success',
        })
      })
      .catch((err) => {
        ElNotification({
          title: 'Ошибка',
          message: `обновления данных для ${
            describer.find((i) => i.tag === service.value?.service_type)?.name
          } | ${err}`,
          type: 'error',
        })
      })
      .finally(() => (loading.value = false))
  }
}
function downLoadFile(file: FileFromServer) {
  if (!!service.value) {
    const params = {
      invoiceId: props.invoiceId,
      serviceId: service.value.id,
      file,
    }
    loader.value?.downloadAdditionalServiceFile(params)
  }
}
function getServices() {
  getAdditionalServices(props.invoiceId).then((res: IAddServicesList) => {
    addServices.value = res?.additional_services
  })
}
function deleteFilesFromCard(fileId: number) {
  loader.value?.onAddDeleteFile(fileId)
  const fileName = service.value?.files.find((file) => file.id === fileId)?.name
  ElMessageBox.confirm(`Вы хотите удалить файл ${fileName}`, 'Внимание!', {
    confirmButtonText: 'Удалить файл',
    cancelButtonText: 'Отменить',
    type: 'warning',
    buttonSize: 'small',
  } as any)
    .then(() => {
      saveFiles()
    })
    .catch(() => {})
}
function spliceStep() {
  loading.value = true
  switchDeclarationStep(props.goodId, { step: ['Add_income'] })
    .then(() => {
      getInvoice(props.invoiceId).then((res) => {
        initAsync(res).then((res) => {
          stepper.selectStep(res)
        })
      })
    })
    .catch((err) => {
      ElNotification({
        title: 'Ошибка',
        message: `запроса перехода на следующий шаг ${err}`,
      })
    })
    .finally(() => (loading.value = false))
}

watch(loader, () => {
  if (!!service.value) {
    loader.value?.setFilesFromServer(service.value?.files.map((i) => transformFileFromServer(i)))
  }
  loader.value?.emitter.on('double_files', (val: string[]) => {
    ElNotification({
      title: 'Ошибка',
      message: `Файл с именем ${val}. Уже загружался ранее`,
      type: 'error',
    })
  })
})

getServices()

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (!!addServices.value)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderAddServices.value, (service) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  key: service.id
                }, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("img", {
                        class: "mr-2",
                        width: "20px",
                        src: _unref(describer).find((i) => i.tag === service.service_type)?.icon,
                        alt: "icon"
                      }, null, 8, _hoisted_6),
                      _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(describer).find((i) => i.tag === service.service_type)?.name), 1)
                    ]),
                    (service.files.length === 0)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          size: "small",
                          onClick: ($event: any) => (initLoader(service.service_type, true)),
                          type: "primary"
                        }, {
                          default: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createTextVNode(" Загрузить файлы ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_el_popover, {
                            onShow: ($event: any) => (initLoader(service.service_type, false)),
                            placement: "right",
                            width: 400,
                            trigger: "click"
                          }, {
                            reference: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_el_icon, {
                                  class: "mr-1",
                                  color: _unref(isDark) ? _unref(colors).dark.ng_green : _unref(colors).light.ng_green
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(SuccessFilled))
                                  ]),
                                  _: 1
                                }, 8, ["color"]),
                                _cache[2] || (_cache[2] = _createElementVNode("p", { class: "dark:text-dark-green text-light-green" }, "Файлы загружены", -1))
                              ])
                            ]),
                            default: _withCtx(() => [
                              (!!loader.value)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                    _createVNode(_unref(FileFromServerItem), {
                                      files: service.files.map((file) => _unref(transformFileFromServer)(file)),
                                      "no-delete": false,
                                      onDelete: deleteFilesFromCard,
                                      onDownload: downLoadFile
                                    }, null, 8, ["files"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["onShow"]),
                          _createVNode(_component_el_button, {
                            onClick: ($event: any) => (initLoader(service.service_type, true)),
                            icon: _unref(SetUp),
                            link: "",
                            type: "primary",
                            size: "small"
                          }, {
                            default: _withCtx(() => _cache[3] || (_cache[3] = [
                              _createTextVNode(" Изменить ")
                            ])),
                            _: 2
                          }, 1032, ["onClick", "icon"])
                        ]))
                  ])
                ])), [
                  [_directive_loading, loading.value]
                ])
              }), 128))
            ])), [
              [_directive_loading, loading.value]
            ])
          : _createCommentVNode("", true),
        (!props.hasAdds)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, _cache[4] || (_cache[4] = [
              _createElementVNode("p", null, " Вы не добавили в заказ дополнительные доходы, если они у вас есть (например: от аренды/продажи квартиры или автомобиля). ", -1),
              _createElementVNode("p", null, "За отчётный год подаётся единая декларация, в которой должны быть отражены все доходы, расходы и вычеты.", -1),
              _createElementVNode("p", null, "Вы можете дозаказать их внесение на любом этапе заказа, написав нам в техподдержку.", -1),
              _createElementVNode("p", null, "Нажмите кнопку «Далее» для продолжения оформления заказа.", -1)
            ])))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_el_button, {
          disabled: !hasFiles.value,
          loading: loading.value,
          onClick: spliceStep,
          icon: _unref(UploadFilled),
          class: "button-main",
          type: "success"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(props.hasAdds ? 'Отправить данные' : 'Далее'), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "icon"])
      ])
    ]),
    _createElementVNode("div", null, [
      (loader.value)
        ? (_openBlock(), _createBlock(_unref(SidebarLoader), {
            key: 0,
            "loader-describer": loader.value,
            "show-instruction": false,
            "show-sidebar": showLoaderSideBar.value,
            "onUpdate:showSidebar": _cache[0] || (_cache[0] = ($event: any) => ((showLoaderSideBar).value = $event)),
            onReadedFile: loader.value.onUpdateFilesEvent,
            onDeleteLocalFile: loader.value.onRemoveLocalFile,
            onDeleteServerFile: loader.value.onAddDeleteFile,
            onUndoDeleteFile: loader.value.undoDeleteFiles,
            onSaveFiles: saveFiles,
            onDownloadServerFile: downLoadFile,
            "show-server-files": "",
            "show-delete-button": ""
          }, null, 8, ["loader-describer", "show-sidebar", "onReadedFile", "onDeleteLocalFile", "onDeleteServerFile", "onUndoDeleteFile"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})