import { DeclarationAction } from '@/entityes/invoice/declaration/declaration.type'
import { GoodsTypeName, IInvoice } from '@/entityes/invoice/invoice.type'
import usePolitic from '@/use/usePolitic'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'

export function useStepper() {
  const router = useRouter()
  const invoiceStore = useInvoiceStore()
  const { isAdmin } = usePolitic()
  const resolvedTms = ['Missing_resolved', 'Transfer_resolved', 'Short_resolved']
  const currentStep = ref<string | undefined>(undefined)

  const steps: Record<string, () => void> = {
    loadFile: () => {
      console.log('load file')

      router.push({
        name: 'file_in_invoice',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    prePayment: () => {
      console.log('pre payment')

      router.push({
        name: 'pre_add_deductions',
        replace: true,
      })
    },
    goodSelect: () => {
      console.log('good select')

      router.push({
        name: 'good_select',
        replace: true,
      })
    },
    tms: () => {
      console.log('tms')
      currentStep.value = 'tms'

      router.push({
        name: 'tms',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    personalData: () => {
      console.log('personalData')
      currentStep.value = 'personal_data'

      router.push({
        name: 'personal_data',
        replace: true,
      })
    },
    addIncomes: () => {
      console.log('addIncome')
      currentStep.value = 'add_incomes'

      router.push({
        name: 'add_incomes',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    deductions: () => {
      console.log('deductions')
      currentStep.value = 'deductions'

      router.push({
        name: 'deductions',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Declaration,
        },
      })
    },
    cfr: () => {
      console.log('cfr')
      currentStep.value = 'cfr'

      router.push({
        name: 'cfr',
        replace: true,
        params: {
          good_id: invoiceStore.invoice?.goods?.Cfr,
        },
      })
    },
    show: () => {
      console.log('show')
      currentStep.value = 'invoice show'

      router.push({
        name: 'invoice show',
        replace: true,
      })
    },
  }
  function selectStep(invoice: IInvoice, has_tms = false) {
    if (invoice.status?.invoice_status === 'payed') return afterPaymentStepper(invoice, has_tms)
    if (invoice.status?.invoice_status === 'new') return steps.loadFile()
    if (invoice.status?.invoice_status === 'file_uploaded') return steps.prePayment()
    if (invoice.status?.invoice_status === 'pre_add_services_selected') return steps.goodSelect()
    // return steps.show()
  }
  function checkingForTms(actions: DeclarationAction[]) {
    if (!actions) return false
    return actions.some((item) => resolvedTms.includes(item))
  }
  function checkingForTmsFromInvoice(actions: IInvoice['tms']) {
    if (!actions) return false
    return Object.values(actions).some((item) => item)
  }
  function afterPaymentStepper(invoice: IInvoice, has_tms = false) {
    const { actions_for_goods }: IInvoice['status'] = invoice.status
    const hasDeclaration = 'Declaration' in actions_for_goods
    const hasCfr = 'Cfr' in actions_for_goods
    const declarationSteps: string[] = hasDeclaration ? actions_for_goods.Declaration : []
    const hasClosedTms = declarationSteps.every((item: string) => resolvedTms.includes(item))
    const cfrSteps = hasCfr ? actions_for_goods.Cfr : []

    if (has_tms && !isAdmin.value) return steps.tms()
    if (isAdmin.value) return

    if (hasDeclaration) {
      if (declarationSteps.includes('Personal_data')) return steps.personalData()
      if (declarationSteps.includes('Add_income')) return steps.addIncomes()
      if (declarationSteps.includes('Deduction')) return steps.deductions()

      if (cfrSteps.length > 0) steps.cfr()
      if (hasClosedTms && !cfrSteps.includes('Add_account')) return steps.show()
    }
    if (!hasDeclaration && hasCfr) {
      if (Object.keys(invoice.personal_data as Record<string, string>).length === 0) return steps.personalData()

      if (cfrSteps.length > 0) return steps.cfr()
      if (!cfrSteps.includes('Add_account')) return steps.show()
    }
  }

  function setCurrentStep(invoice: IInvoice, has_tms = false) {
    if (has_tms) return (currentStep.value = 'tms')

    const { actions_for_goods }: any = invoice.status
    const hasDeclaration = 'Declaration' in actions_for_goods
    const hasCfr = 'Cfr' in actions_for_goods
    const declarationSteps = actions_for_goods.Declaration
    const cfrSteps = actions_for_goods.Cfr

    if (hasDeclaration) {
      if (declarationSteps.includes('Personal_data')) return (currentStep.value = 'personal_data')
      if (declarationSteps.includes('Add_income')) return (currentStep.value = 'add_incomes')
      if (declarationSteps.includes('Deduction')) return (currentStep.value = 'deductions')
      if (hasCfr && cfrSteps.length > 0) return (currentStep.value = 'cfr')

      if (declarationSteps.every((item: string) => resolvedTms.includes(item))) {
        if (hasCfr) {
          return (currentStep.value = !cfrSteps.includes('Add_account') ? 'invoice show' : 'cfr')
        } else {
          currentStep.value = 'invoice show'
          return
        }
      }
    }
    if (hasCfr && !hasDeclaration) {
      if (!cfrSteps.includes('Add_account')) return (currentStep.value = 'invoice show')
      currentStep.value =
        Object.keys(invoice.personal_data as Record<string, any>).length === 0 ? 'personal_data' : 'cfr'
    }
  }

  return {
    selectStep,
    checkingForTms,
    checkingForTmsFromInvoice,
    currentStep,
    setCurrentStep,
  }
}
