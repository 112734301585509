<script setup lang="ts">
import { computed, watch } from 'vue'
import { IInvoice } from '@/entityes/invoice/invoice.type'
import { IMenuItem, useInvoiceMenu } from '@/07-Shared/model/useInvoiceMenu'
import { isArray, isString } from 'lodash'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'

interface IProps {
  invoice: IInvoice | undefined
  currentStep: string | undefined
  hasTms: boolean
  hasResolvedTms: boolean
}

const props = defineProps<IProps>()
const { menuTitles } = useInvoiceMenu()
const allSteps = computed(() =>
  menuTitles.filter((i) => i.serverTag).sort((item, item2) => item.sortIndex - item2.sortIndex)
)

const filteredSteps = computed(() => {
  return allSteps.value.filter((i: IMenuItem) => {
    const declarationSteps = props.invoice?.status.actions_for_goods?.Declaration
    const cfrSteps = props.invoice?.status.actions_for_goods?.Cfr

    if (isArray(i.serverTag)) {
      if (props.hasTms || props.hasResolvedTms) {
        return i
      }
    } else if (isString(i.serverTag)) {
      if (cfrSteps) {
        if (['Personal_data', 'Add_account'].includes(i.serverTag)) {
          return i
        }
      }
      if (declarationSteps) {
        if (i.serverTag !== 'Add_account') {
          return i
        }
      }
    }
    if (i.serverTag === 'invoice show') {
      return i
    }
  })
})

const stepIndexMap = computed(() =>
  filteredSteps.value.reduce<Record<string, number>>((acc, step, index) => {
    acc[step.tag] = index
    return acc
  }, {})
)

const active = computed(() => {
  if (!props.currentStep) return 0
  if (props.currentStep === 'invoice show') {
    return 6
  } else {
    return stepIndexMap.value[props.currentStep] ?? 0
  }
})
</script>

<template>
  <div class="container-list">
    <!--    <div class="flex justify-between">-->
    <el-steps :active="active" finish-status="success">
      <el-step v-for="item in filteredSteps" :title="item.title" :key="item.tag" />
    </el-steps>
    <!--      <div class="w-44 flex-none">-->

    <!--    </div>-->
  </div>
</template>

<style scoped lang="sass"></style>
