import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { computed, reactive } from 'vue'

export default function useSummaryNew() {
  const invoiceStore = useInvoiceStore()

  const summary = reactive([
    {
      personal_data:
        Object.keys(invoiceStore.invoice?.personal_data || {}).length === 0
          ? 'не заполнено'
          : invoiceStore.invoice?.personal_data?.title,
    },
  ])

  const taxes13 = computed(() => invoiceStore.invoice?.tax_to_pay13)
  const taxes15 = computed(() => invoiceStore.invoice?.tax_to_pay15)

  return {
    summary,
    taxes13,
    taxes15,
  }
}
