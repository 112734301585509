import { useCfrStore } from '@/entityes/invoice/cfr/cfr.store'
import { useRequestService } from '@/use/useRequestService'
import { IBrokerAccount } from '@/entityes/invoice/cfr/cfr.type'

export function useCfrRepository() {
  const { fetch } = useRequestService()
  const cfrStore = useCfrStore()

  function getBrokerAccounts(cfrId: string | number) {
    return new Promise((resolve, reject) => {
      fetch<{ broker_accounts: IBrokerAccount[] }>(`/cfrs/${cfrId}/broker_accounts`)
        .then((res) => {
          cfrStore.brokerAccounts = res.broker_accounts
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  function createBrokerAccount(cfrId: string | number, body: IBrokerAccount) {
    return fetch(`/cfrs/${cfrId}/broker_accounts`, {
      method: 'post',
      body,
    })
  }

  function destroyBrokerAccount(cfrId: string | number, brokerAccountId: string | number) {
    return fetch(`/cfrs/${cfrId}/broker_accounts/${brokerAccountId}`, {
      method: 'delete',
    })
  }

  function updateBrokerAccount(cfrId: string | number, body: IBrokerAccount) {
    return fetch(`/cfrs/${cfrId}/broker_accounts/${body.id}`, {
      method: 'patch',
      body,
    })
  }
  function showBrokerAccount(cfrId: string | number, brokerAccountId: string | number) {
    return fetch(`/cfrs/${cfrId}/broker_accounts/${brokerAccountId}`)
  }

  function createXmlCfr(cfrId: string | number) {
    return fetch(`/cfrs/${cfrId}/render_xml`, {
      method: 'patch',
    })
  }

  function switchCfrStep(cfrId: string | number, body: { step: string[] }) {
    return fetch(`/cfrs/${cfrId}/splice_step`, {
      method: 'patch',
      body,
    })
  }
  return {
    getBrokerAccounts,
    createBrokerAccount,
    updateBrokerAccount,
    destroyBrokerAccount,
    showBrokerAccount,
    createXmlCfr,
    switchCfrStep,
  }
}
