<script lang="ts" setup>
import useWindowWidth from '@/use/useWindowWidth'

const { screenLessValue } = useWindowWidth()
</script>

<template>
  <h1 class="mb-4">Дополнительные доходы</h1>
  <p class="margin-v-s">Загрузите все необходимые документы по добавленным в заказ доходам.</p>
</template>

<style lang="sass" scoped></style>
